import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import { debounce } from "lodash";
import { OlderNews } from "./ParishEvents";

const Youtube = ({ events }) => {
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const cache = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchVideoData = useCallback(
    debounce(async () => {
      const cachedData = localStorage.getItem("youtubeVideoData");
      if (cachedData) {
        console.log("Using cached data from localStorage.");
        setVideoData(JSON.parse(cachedData));
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          "https://www.googleapis.com/youtube/v3/playlistItems",
          {
            params: {
              part: "snippet",
              playlistId: "UUb_oIWxckR_bC9Tyi4y9mtA",
              maxResults: 1,
              key: "AIzaSyD9lHZ1Irk6gz1Ur6QW4BR8QsVmsm66fCA",
            },
          }
        );

        const uniqueVideos = response.data.items.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) => t.snippet.resourceId.videoId === item.snippet.resourceId.videoId
            )
        );

        console.log("Fetched new data.");
        setVideoData(uniqueVideos);
        setLoading(false);
        localStorage.setItem("youtubeVideoData", JSON.stringify(uniqueVideos));
        cache.current = uniqueVideos;
      } catch (error) {
        console.error("Error fetching video data:", error);
        setError("There was a problem fetching video data. Please try again later.");
        setLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    fetchVideoData();
    return () => fetchVideoData.cancel();
  }, [fetchVideoData]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const latestVideo = videoData[0];

  return (
    <div className="container mt-3">
      <div className="row" style={{ display: "flex", justifyContent: "space-between", height: "100%" }}>
        {latestVideo && (
          <div className="col-12 col-md-5 mb-3">
            <iframe
              src={`https://www.youtube.com/embed/${latestVideo.snippet.resourceId.videoId}`}
              title={latestVideo.snippet.title}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
                padding: "4px",
              }}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
            <p className="text-center" id="yt-title">{latestVideo.snippet.title}</p>
          </div>
        )}
        <div className="col-12 col-md-2 mb-3 mt-5">
          <h6 className="text-left text-primary watchmore" style={{ fontSize: "12px", marginLeft: "35px" }}>Watch more</h6>
          <OlderNews olderEvents={events.slice(0, 4)} />
        </div>
        <div className="col-12 col-md-4 d-flex flex-column align-items-center">
          <img
            src={'assets/images/massoffers.webp'}
            alt="Latest video thumbnail"
            style={{
              width: "100%", height: "100%", objectFit: "cover", border: "none",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
              padding: "4px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Youtube;
